<template>
  <div id="map"></div>
</template>

<script>
import Mapbox from 'mapbox-gl'
import MapboxGeocoder from 'mapbox-gl-geocoder'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

export default {
  name: 'MapBoxLoader',

  props: {
    location: null,
    editMode: {}
  },

  data () {
    return {
      accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
      mapStyle: process.env.VUE_APP_MAPBOX_MAP_STYLE,
      center: [-47.896821, -15.785684],
      map: null,
      geocoder: null,
      marker: null
    }
  },

  mounted () {
    if (this.map == null) {
      this.renderMap()
    }

    if (this.location) {
      this.reload(this.location)
      this.addMarker(this.location)
    } else {
      this.geolocate()
    }
  },

  methods: {
    renderMap () {
      Mapbox.accessToken = this.accessToken

      this.map = new Mapbox.Map({
        container: 'map',
        style: this.mapStyle,
        center: this.center,
        zoom: 15
      })

      if (this.editMode) {
        this.renderGeocoder()
      }
      this.map.addControl(new Mapbox.NavigationControl())

      this.map.on('click', event => {
        if (this.editMode) {
          var lat = event.lngLat.lat.toFixed(4)
          var lng = event.lngLat.lng.toFixed(4)

          this.removeMarker()
          this.addMarker([lng, lat])
        }
      })
    },

    renderGeocoder () {
      this.geocoder = new MapboxGeocoder({
        accessToken: this.accessToken,
        mapboxgl: Mapbox,
        marker: false,
        placeholder: 'Buscar imóvel'
      })

      this.map.addControl(this.geocoder)

      this.geocoder.on('result', event => {
        var location = event.result.geometry.coordinates
        this.removeMarker()
        this.addMarker(location)
      })
    },

    addMarker: function (location) {
      this.marker = new Mapbox.Marker().setLngLat(location).addTo(this.map)
      this.$emit('click', location)
    },

    removeMarker: function () {
      if (this.marker != null) {
        this.marker.remove()
      }
    },

    reload (location) {
      this.map.flyTo({
        center: location
      })
    },

    geolocate: function () {
      navigator.geolocation.getCurrentPosition(position => {
        this.map.flyTo({
          center: [position.coords.longitude, position.coords.latitude]
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#map {
  position: relative;
  height: 366px;
  width: 100%;
  border-radius: 20px;
}
</style>
