<template>
  <div class="properties">
    <div v-if="loader" class="loader">
      <img src="~@/assets/loader.gif" alt="">
      <p>Carregando informações do imóvel...</p>
    </div>
    <section v-if="!loader" class="properties-header">
      <div class="container">
        <ul>
          <li>{{ properties?.state?.name }}</li>
          <li><span>•</span>{{ properties?.region?.name }}</li>
        </ul>
        <a href="#lead" class="button-primary">Encontre aqui o seu imóvel</a>
      </div>
    </section>
    <section v-if="!loader" class="properties-image">
      <div class="main-image" @click="openImage(properties.images[0], 0)">
        <img v-if="properties.images[0]" :src="properties.images[0]" alt="" />
        <img v-if="!properties.images[0]" src="~@/assets/placeholder-propertie.png" alt="">
        <div class="image-logos">
          <a v-if="properties?.tour_url != '' && properties?.tour_url != 'null'" :href="properties?.tour_url" target="_blank" class="button-360"><img src="~@/assets/image-360.svg" alt="" /></a>
        </div>
      </div>
      <div class="more-images">
        <div class="open-modal" @click="openImage(properties.images[1], 1)">
          <img v-if="properties.images[1]"  :src="properties.images[1]" alt="" />
          <img v-if="!properties.images[1]" src="~@/assets/placeholder-propertie.png" alt="" >
        </div>
        <div class="open-modal" @click="openImage(properties.images[2], 2)">
          <img v-if="properties.images[2]" :src="properties.images[2]" alt="" />
          <img v-if="!properties.images[2]" src="~@/assets/placeholder-propertie.png" alt="">
        </div>
        <div class="open-modal" @click="openImage(properties.images[3], 3)">
          <img v-if="properties.images[3]" :src="properties.images[3]" alt="" />
          <img v-if="!properties.images[3]" src="~@/assets/placeholder-propertie.png" alt="">
        </div>
        <div class="open-modal" @click="openImage(properties.images[4], 4)">
          <img v-if="properties.images[4]" :src="properties.images[4]" alt="" />
          <img v-if="!properties.images[4]" src="~@/assets/placeholder-propertie.png" alt="">
        </div>
      </div>
    </section>
    <section v-if="!loader" class="properties-main">
      <div class="container">
        <div class="properties-columns">
          <div class="contact" id="lead">
            <div>
              <div class="success-lead" v-if="sucessLead">
                <img src="~@/assets/check-lead.svg" alt="" />
                <p>Obrigado por entrar em contato!</p>
              </div>
              <div v-if="!sucessLead">
                <h3>Gostaria de saber mais sobre o imóvel?</h3>
                <p>Deixe seu melhor contato que logo entraremos em contato!</p>
              </div>
              <form @submit.prevent="createLead()" v-if="!sucessLead">
                <div class="input">
                  <input type="text" v-model="lead.name" id="name" placeholder="Nome" />
                </div>
                <div class="input">
                  <input type="text" v-model="lead.email" id="email" placeholder="Insira seu e-mail" />
                </div>
                <div class="input">
                  <the-mask :mask="['(##) ####-####', '(##) #####-####']" v-model="lead.phone_number" id="phone_number" placeholder="Insira seu telefone" />
                </div>
                <button type="submit" class="button-primary" :disabled="!disabledButton">Enviar</button>
              </form>
            </div>
          </div>
          <div>
            <div class="section">
              <h2>{{ properties?.name }}</h2>
              <h3>R$ {{ getPrice() }}</h3>
              <ul>
                <li>{{ getPropertyTypes }}</li>
                <li><img src="~@/assets/icons/icon-pin.svg" alt="" />{{ properties?.region?.name }}-{{ properties?.state?.abbreviation }}</li>
                <li><img src="~@/assets/icons/icon-straighten.svg" alt="" />{{ properties?.footage || 0  }}mº</li>
                <li><img src="~@/assets/icons/icon-hotel.svg" alt="" />{{ properties?.property_descriptor?.number_of_bedrooms || 0 }} {{ properties?.property_descriptor?.number_of_bedrooms > 1 ? 'quartos' : 'quarto' }}</li>
                <li><img src="~@/assets/icons/icon-hotel.svg" alt="" />{{ properties?.property_descriptor?.number_of_suites || 0 }} {{ properties?.property_descriptor?.number_of_suites > 1 ? 'suítes' : 'suíte' }}</li>
                <li><img src="~@/assets/icons/icon-bathtub.svg" alt="" />{{ properties?.property_descriptor?.number_of_bathrooms || 0 }} {{ properties?.property_descriptor?.number_of_bathrooms > 1 ? 'banheiros' : 'banheiro' }}</li>
                <li><img src="~@/assets/icons/icon-directions-car.svg" alt="" />{{ properties?.property_descriptor?.number_of_parking_spaces || 0 }} {{ properties?.property_descriptor?.number_of_parking_spaces > 1 ? 'vagas' : 'vaga' }}</li>
              </ul>
              <div class="post-content" v-html="properties.description"></div>
            </div>

            <div class="benefits">
              <h2>Vantagens</h2>
              <div class="benefits-list">
                  <div
                    class="item"
                    v-for="feature in properties.features"
                    :value="feature.id"
                    :key="feature.id"
                  >
                    <img src="~@/assets/icons/check-circle.svg" alt="" />
                    <p>{{ feature.name }}</p>
                  </div>
              </div>
            </div>

            <div class="localization">
              <h2>Localização do imóvel</h2>
              <div>
                <map-box-loader
                  style="border-radius: 20px"
                  :location="location"
                  :editMode="false"
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <modal
      @close="showModal = false"
      v-show="showModal"
      class="modal-images-property"
    >
      <template slot="body">
          <div class="imob-gallery">
            <div class="imob-gallery_main">
              <img :src="showImage" alt="">
            </div>
            <div class="imob-gallery_controls">
              <a @click="prevImage()"><img src="@/assets/arrow-left-modal.svg" alt=""></a>
              <a @click="nextImage()"><img src="@/assets/arrow-right-modal.svg" alt=""></a>
            </div>
            <div class="imob-gallery_thumbnails">
              <ul>
                <li v-for="(image, index) in properties.images" :key="index">
                  <img :src="image" alt="" @click="activateImage(image, index)" />
                </li>
              </ul>
            </div>
          </div>

      </template>
    </modal>
  </div>
</template>

<script>
import { propertiesService } from '@/services/properties.service'
import { leadsService } from '@/services/leads.service'
import MapBoxLoader from '@/components/maps/MapBoxLoader'
import Modal from '@/components/shared/Modal'

export default {
  name: 'Properties',

  components: {
    MapBoxLoader,
    Modal
  },

  data () {
    return {
      showImage: '',
      loader: true,
      sucessLead: false,
      showModal: false,
      properties: {},
      location: [],
      propertyTypes: [],
      lead: {},
      activeImage: 0,
      localArrayImages: []
    }
  },

  computed: {
    disabledButton () {
      return (
        this.lead.name &&
        this.lead.email &&
        this.lead.phone_number
      )
    },

    getPropertyTypes () {
      const types = this.properties.property_type
      if (types != null) {
        const value = this.propertyTypes.filter(item => {
          return item.key === types
        })
        return value.length > 0 ? value[0].name : 'Não informado'
      } else {
        return 'Não informado'
      }
    }
  },

  mounted () {
    document.title = 'imoGo - Imóvel'
    this.getInfoProperties(this.$route.params.id)
    this.getTypes()
  },

  methods: {
    async getInfoProperties (id) {
      try {
        const infosProperties = await propertiesService.getProperties(id)
        this.properties = infosProperties.data.data.attributes
        this.showImage = infosProperties.data.data.attributes.images[0]
        this.location = [parseFloat(infosProperties.data.data.attributes.longitude), parseFloat(infosProperties.data.data.attributes.latitude)]
        this.localArrayImages = infosProperties.data.data.attributes.images
        document.title = `imoGo - ${this.properties?.name || ''}`

        if (this.properties.building_images.length > 0) {
          const index = this.properties.building_images.length - 1
          const image = this.properties.building_images[index]
          this.localArrayImages.unshift(image)
        }
        this.loader = false
      } catch (error) {
        console.log('Ocorreu um erro inesperado.')
      }
    },

    getPrice () {
      let value = this.properties?.price

      if (value) {
        value = value / 100
        value = (value / 1).toFixed(2).replace('.', ',')
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      return ''
    },

    getTypes () {
      this.propertyTypes = [
        { key: 'house', name: 'Casa' },
        { key: 'apartment', name: 'Apartamento' },
        { key: 'flat', name: 'Flat/Apart-hotel' },
        { key: 'studio', name: 'Kitnet/Studio' },
        { key: 'room', name: 'Sala' },
        { key: 'shop', name: 'Loja' },
        { key: 'lot', name: 'Lote' },
        { key: 'rural', name: 'Rural' },
        { key: 'building', name: 'Prédio' },
        { key: 'shed', name: 'Galpão' }
      ]
    },

    getStatus (key) {
      var status = '-'
      switch (key) {
        case 'under_construction':
          status = 'Em construção'
          break
        case 'release':
          status = 'Lançamento'
          break
        case 'ready_live':
          status = 'Pronto para morar'
          break
        case 'ready_build':
          status = 'Pronto para construir'
          break
        case 'pre_release':
          status = 'Pré Lançamento'
          break
        case 'done':
          status = 'Pronto'
          break
        case 'sold':
          status = '100% vendido'
          break
      }
      return status
    },

    async createLead () {
      const payload = {
        name: this.lead.name,
        phone_number: this.lead.phone_number,
        email: this.lead.email,
        property_id: this.$route.params.id
      }
      try {
        await leadsService.sendLead(payload)
        this.sucessLead = true
      } catch (error) {
        console.log('Ocorreu um erro inesperado.')
      }
    },

    openImage (image, index) {
      this.showModal = true
      this.activateImage(image, index)
    },

    activateImage (url, imageIndex) {
      this.showImage = url
      this.activeImage = imageIndex
    },

    nextImage () {
      if (this.activeImage + 1 < this.localArrayImages.length) {
        this.activeImage = this.activeImage + 1
        this.showImage = this.localArrayImages[`${this.activeImage}`]
      }
    },

    prevImage () {
      if (this.activeImage - 1 >= 0) {
        this.activeImage = this.activeImage - 1
        this.showImage = this.localArrayImages[`${this.activeImage}`]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .properties {
    margin-top: 0;
    position: relative;

    .modal-images-property {
      ::v-deep.modal-backoffice .modal-body {
        padding: 0px 10px 0px;
      }
    }

    .loader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 300px;
      margin: 100px 0;
    }

    .properties-header {
      background: var(--grey-custom);
      padding: 10px 0;
      height: 60px;
      position: sticky;
      width: 100%;
      z-index: 20;
      top: 72px;
      left: 0;

      .container {
        max-width: 1200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .button-primary {
        border-radius: 16px;
        max-width: 280px;
        width: 100%;
        font-size: 14px;
        padding: 8px 16px 7px;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          font-size: var(--size-regular);
          line-height: 36px;
          color: var(--secundary-black);
          font-weight: var(--weight-light);
          display: inline-block;
          vertical-align: middle;

          span {
            margin: 0 6px;
          }

          &:first-child {
            font-weight: var(--weight-bold);
          }
        }
      }
    }

    .properties-image {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      max-width: 1200px;
      margin: 24px auto 0;

      div {
        width: 50%;
        position: relative;

        &.main-image {
          position: relative;
          img {
            max-width: 960px;
            width: 100%;
            height: 468px;
            object-fit: cover;
            cursor: pointer;
            border-radius: 20px 0 0 20px;
          }
        }

        &.more-images{
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex: 1;
          flex-wrap: wrap;

          div {
            max-width: 459px;
            height: 100%;

            &:nth-child(2) {
              img {
                border-radius: 0 20px 0 0;
              }
            }

            &:nth-child(4) {
              img {
                border-radius: 0 0 20px 0;
              }
            }

            &.box-modal {
              position: relative;

              .open-modal {
                position: absolute;
                top: 0;
                width: calc(100% - 20px);
                left: 20px;
                height: calc(100% - 25px);
                background: rgba(0,0,0,0.3);
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--white);
                font-size: 55px;
                cursor: pointer;
              }
            }

            &.open-modal {
              cursor: pointer;
            }
          }
          img {
            max-width: 459px;
            width: calc(100% - 20px);
            margin: 0 0 18px 20px;
            height: 221px;
            object-fit: cover;
          }
        }
      }

      .image-logos {
        position: absolute;
        bottom: -40px;
        left: 5%;
        display: flex;
        width:  302px;
        justify-content: space-between;
        align-items: center;

        img {
          display: block;
          margin: 0 !important;
          padding: 0;
        }

        .builder {
          width: auto;
            img {
              width: 100px;
              height: 100px;
            }
          }

        .button-360 {
          width: auto;
          img {
            width: 189px;
            height: 66px;
            cursor: pointer;
          }
        }

      }

    }

    .properties-main {

      .container {
        padding-top: 70px;
        padding-bottom: 100px;
      }

      .properties-columns {
        display: flex;
        gap: 80px;
      }

      h2 {
        color: var(--secundary-black);
        font-weight: var(--weight-bold);
        line-height: 36px;
        font-size: var(--size-h2);
        margin-bottom: 12px;
      }

      h3 {
        color: var(--grey-dark-custom);
        font-weight: var(--weight-bold);
        line-height: 36px;
        font-size: var(--size-h3);
        margin-bottom: 8px;
      }

      .section {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 60px;
        padding-bottom: 40px;
        border-bottom: 1px solid var(--gainsboro);

        .post-content {
          padding: 0 0 0 0;
        }

        ul {
          list-style: none;
          display: flex;
          margin: 32px 0;
          gap: 16px;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;

          li {
            border-right: 1px solid rgba(65, 79, 84, 0.39);
            padding: 0 16px 0 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            max-width: fit-content;

            img {
              margin-right: 4px;
            }

            &:last-child {
              border: none;
            }
          }
        }
      }

      .tag {
          background: var(--orange-custom);
          color: var(--gainsboro);
          font-size: var(--size-small);
          display: flex;
          align-items: center;
          height: 33px;
          max-width: 130px;
          width: 100%;
          padding: 0 0;
          border-radius: 10px;
          justify-content: center;
          margin: 0 0 24px;
        }

      .benefits {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        flex-direction: column;
        margin-bottom: 60px;
        padding-bottom: 40px;
        border-bottom: 1px solid var(--gainsboro);

        h2 {
          font-weight: 500;
          font-size: 28px;
          line-height: 36px;
          color: var(--secundary-black);
          margin-bottom: 40px;
        }

        .benefits-list {
          margin: 0;
          padding: 0;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          list-style: none;
          width: 100%;
          flex-wrap: wrap;
          gap: 24px;

          .item {
            width: 100%;
            max-width: 300px;
            padding: 12px 9px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: var(--size-base);
            line-height: 16px;
            color: var(--grey-dark-custom);

            img {
              margin-right: 8px;
            }
          }
        }
      }

      .localization {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 60px;
        padding-bottom: 80px;
        border-bottom: 1px solid var(--gainsboro);

        h2 {
          font-weight: 500;
          font-size: 28px;
          line-height: 36px;
          color: var(--secundary-black);
          margin-bottom: 40px;
        }

        div:last-child {
          max-width: 770px;
          width: 100%;
          border-radius: 20px;

          ::v-deep.mapboxgl-canvas-container canvas {
            border-radius: 20px;
          }
        }
      }

      .builders {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 140px;

        h2 {
          font-weight: 500;
          font-size: 28px;
          line-height: 36px;
          color: var(--secundary-black);
          margin: 0 0;
        }

        img {
          max-width: 290px;
          height: auto;
          display: block;
        }
      }

      .contact {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        max-width: 339px;
        width: 100%;

        h2 {
          span {
            font-weight: var(--weight-light);
            display: block;
            line-height: 53px;
            margin-bottom: 8px;
          }
        }

        h3 {
          color: var(--secundary-black);
          font-weight: 300;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 8px;
        }

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: var(--secundary-black);
          margin: 0 0 60px;
        }

        div:first-child {
          width: 100%;
        }

        > div {
          padding: 42px 24px;
          max-width: 365px;
          width: 100%;
          background: var(--grey-custom);
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          border-radius: 20px;
          position: relative;
          z-index: 9;

          .success-lead {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p {
              margin-bottom: 0;
              text-align: center;
            }

            img {
              max-width: 110px;
              margin-bottom: 30px;
            }
          }

          form {
            max-width: 327px;
            width: 100%;

            input {
              height: 50px;
              display: flex;
              align-items: center;
              padding: 14px 16px;
              font-size: var(--size-base);
              color: rgba(0,0,0,0.5);
              border-radius: 12px;
              border: 1px solid rgba(0, 0, 0, 0.5);
              width: 100%;
              margin: 0 0 12px;
              background: var(--grey-custom);
            }

            .button-primary {
              border: none;
              border-radius: 16px;
              display: block;
              width: 100%;
              margin: 24px 0 0;
            }
          }
        }
      }
    }

    @media (max-width: 768px) {
      margin-top: 0px;

      .properties-columns {
        flex-direction: column-reverse;
      }
      .properties-header {
        margin-top: 60px;
        height: auto;
        position: initial;

        .container {
          flex-direction: column;
          align-items: flex-start;
        }

        ul {
          margin-bottom: 16px;
          li {
            font-size: var(--size-regular);
          }
        }
      }

      .properties-image {
        flex-direction: column;

          div.main-image {
            margin-bottom: 68px;
            width: 100%;

            img {
              height: 248px;
            }
          }

          .more-images {
            width: 100%;

            div {
              width: 50%;
              img {
                width: calc(100% - 10px);
                margin: 0 5px 3px 5px;
                height: auto;
              }
            }
          }

        .image-logos {
          bottom: -70px;
          left: inherit;
          width: 100%;
          text-align: center;
          padding: 0 16px;
        }
      }

      .properties-main {
        .container {
          padding-top: 30px;
          padding-bottom: 60px;
        }

        .section {
          ul {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            li {
              border: none;
            }
          }
        }

        .section,
        .benefits,
        .localization,
        .builders,
        .contact {
          flex-direction: column;
          margin-bottom: 40px;

          div:first-child {
            margin-bottom: 16px;
          }
        }

        .benefits {
          ul {
            li {
              width: 47%;
            }
          }
        }

        .localization {
          img {
            width: 100%;
          }
        }

        .contact {
          div:last-child {
            &::before,
            &::after {
              display: none;
            }

            height: auto;
            padding: 20px;
          }
        }
      }

    }
  }

  .imob-gallery {
    position: relative;

    .imob-gallery_main {
      max-width: 845px;
      margin: 0 auto;
      img {
        max-width: 845px;
        width: 100%;
        height: 517px;
        object-fit: cover;
        border-radius: 10px;

        @media (max-width: 768px) {
          height: 280px;
        }

        @media (max-width: 320px) {
          height: 180px;
        }
      }
    }

    .imob-gallery_controls {
      position: absolute;
      bottom: calc(50% + 15px);
      width: 100%;
      display: flex;
      justify-content: space-between;

      a {
        cursor: pointer;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .imob-gallery_thumbnails {
      max-width: 845px;
      margin: 16px auto 0;
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        list-style: none;
        li {
          margin: 0 16px 0 0;
          cursor: pointer;

          img {
            width: 156px;
            height: 111px;
            border-radius: 10px;
            object-fit: cover;
          }
        }
      }

      @media (min-width: 1024px) {
        ul {
          max-width: 845px;
          overflow-x: scroll;
        }
      }

      @media (max-width: 768px) {
        ul {
          justify-content: space-between;
          flex: 1;
          flex-wrap: wrap;
          li {
            margin: 0 0;

            img {
              width: 114px;
              height: 81px;
            }
          }
        }
      }

      @media (max-width: 320px) {
        ul {
          li {
            img {
              width: 120px;
              height: 65px;
            }
          }
        }
      }
    }
  }
</style>
